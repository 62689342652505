import { Formik, FormikHelpers } from 'formik'
import React from 'react'
import { Button } from 'react-daisyui'

import TextInput from '~/components/Input/Text'
import LoginSchema from '~/pages/auth/login/components/login-form.schema'
import { FormValues } from '~/pages/auth/login/index.page.tsx'

interface LoginFormProps {
  onSubmit: (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => void
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  return (
    <Formik
      initialValues={{ email: 'admin@waveen.com', password: 'test123' }}
      validationSchema={LoginSchema}
      validateOnBlur
      onSubmit={onSubmit}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <TextInput
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                label="Email"
                name="email"
                type="email"
                autoComplete="email"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                error={touched.email && errors.email}
              />
              <TextInput
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                label="Password"
                name="password"
                type="password"
                autoComplete="password"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                error={touched.password && errors.password}
              />

              <div>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  Sign in
                </Button>
                <div className="flex justify-center mt-8">
                  <a href="/auth/registration" className="link text-center">
                    Registration
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default LoginForm
